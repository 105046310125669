/* PopupWithInput.css */

.modals {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-contents {
  width: 50%;
  height: 20%;
  background: #fff;

  padding: 20px; /* Adjust padding as needed */
  border-radius: 10px; /* Adjust border-radius for rounder corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
