.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-alert {
  padding: 0.25rem 0.5rem; /* Adjust padding as needed */
  line-height: 1.5; /* Set desired line height */
  font-size: 0.9rem; /* Adjust font size */

  border-radius: 0.25rem; /* Border radius for rounded corners */
  margin-bottom: 0.2rem; /* Space between alerts */
}

.custom-alert-danger {
  background-color: #f8d7da; /* Light red background for failed status */
}

.custom-alert-success {
  background-color: #d4edda; /* Light green background for success status */
}
.floating-button {
  position: fixed;
  bottom: 150px; /* Adjust as needed */
  right: 60px; /* Adjust as needed */
  z-index: 1000; /* Ensure it stays on top */
}
.floating-button-top {
  position: fixed;
  top: 80px; /* Adjust as needed */
  right: 150px; /* Adjust as needed */
  z-index: 1000; /* Ensure it stays on top */
}
.floating-button-end {
  position: fixed;
  bottom: 150px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 1000; /* Ensure it stays on top */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Hide the text initially */
.nav-text {
  display: none;
  position: absolute;
  left: 100%; /* Adjust to position the text outside the nav */
  white-space: nowrap; /* Prevents text from wrapping */
  background-color: #5bd2f6; /* Same as nav background to blend */
  padding: 5px;
  border-radius: 3px;
  z-index: 10; /* Ensures it is above other content */
}
/* Show the text on hover */
.nav-link-hover:hover .nav-text {
  display: inline;
}

.nav-link-hovers {
  position: relative; /* Make sure the hover effect applies to the wrapper */
}

.nav-text-container {
  position: absolute;
  left: 100%; /* Position the container outside the nav */
  top: 0; /* Align with the top of the nav */
  padding: 5px 10px;
  z-index: 1000;
}

.nav-texts {
  display: none;
  background-color: #5bd2f6;
  color: white;
  padding: 10px 12px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  border: none;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.nav-link-hovers:hover .nav-text-container .nav-texts {
  display: inline-block; /* Show the button on hover */
}

.active {
  font-weight: bold;
  color: rgb(255, 0, 13);
}
.inActive {
  font-weight: normal;
  color: white;
}
.magic-button {
  border: none;
  color: rgb(0, 0, 0);
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;

  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.magic-button-green {
  background-color: #aee8b0; /* Green */
}

.magic-button-yellow {
  background-color: #f4e9a6; /* Red */
}

.magic-button-blue {
  background-color: #b0daf9; /* Red */
}
.magic-button-red {
  background-color: #efa3a3; /* Red */
}
.cell-red {
  background-color: #efa3a3; /* Red */
  border-radius: 6px; /* Adjust as needed */
  overflow: hidden;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Transparent background with slight black overlay */
  z-index: 9999; /* Ensure the loading overlay stays on top */
}

.print-page {
  width: 200mm;
  border: solid#cac6c6;
  border-radius: 3%;
  padding: 5mm;
  margin-bottom: 5mm;
}

@media print {
  body {
    padding: 5mm;
  }

  .print-page {
    border: none;
    margin-top: 5mm;
    page-break-before: always;
  }

  .print-page:last-child {
    page-break-after: auto;
  }

  .no-print {
    display: none;
  }
}
table,
td {
  border: 1px solid #dedede;
  padding: 2px;
  border-collapse: collapse;
}
.fontB {
  font-weight: bold;
  font-size: 11px;
}
.fontM {
  font-size: 11px;
}
.fontL {
  font-size: 12px;
}
.fontS {
  font-size: 10px;
}
.dotted-line {
  border: none;
  border-top: 2px dotted black; /* Adjust thickness and color as needed */
  margin: 20px 0; /* Add some spacing */
}
