.timeline-container {
  overflow-y: auto;
  position: relative;
  padding: 20px;
  max-height: 1000px;
}

.timeline-rod {
  position: absolute;
  left: 50px; /* Adjust this value based on your layout */
  top: 0;
  bottom: 0;
  width: 10px;
  background: #ccc;
}

.timeline-event {
  position: relative;
  margin-left: 100px; /* Adjust this value based on the rod position */
  margin-bottom: 20px;
  background: #f9f9f9;
  padding: 6px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.timeline-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: small;
}
.timeline-event::before {
  content: "";
  position: absolute;
  left: -50px; /* Adjust this value based on the rod position */
  top: 20px; /* Adjust this value to align with the rod */
  width: 50px;
  height: 2px;
  background: #ccc;
}

/* .timeline-content h6 {
  margin: 0 0 10px;
} */

.timeline-date {
  display: block;
  margin-top: 10px;
  color: #888;
}
.timeline-status {
  overflow: auto;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  max-width: 300px; /* Adjust this value to the desired width */
}
